import React from 'react'
import B2B from '../components/B2b';
import Header from '../components/Header';

function showcases() {
  return (
    <div className="container">
    <Header/>
    <B2B/>
    </div>
  )
}

export default showcases