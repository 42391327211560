import React from 'react';
import otterGIF from '../../images/busi.gif';
import { Link } from "gatsby";
import './B2B.scss'

function B2B() {
  return (
    <div className='wrapper wrapper_b2b'>
        <div className='snippet'>
            <div className='snippet_icon'>
            <img src={otterGIF} alt="Otter dancing with a fish" />
            </div>
            <div className='snippet_text'>
                <h1>Юркнопка - бизнес🎉</h1>
                <p>Мы предлагаем партнерство владельцам юридических компаний, тематических сайтов, специализированных порталов.
                    <br></br>
                  <ul>
                      <li>Если Вы хотите <strong>разместить кнопку на своем ресурсе</strong> и получать доход - напишите нам на <strong>org@pravdo.ru</strong> письмо с информацией о вашем портале и количеством траффика.</li>
                      <li>Если Вы хотите <strong>разработать уникальную кнопку</strong> под Ваши задачи напишите нам <strong>org@pravdo.ru</strong> и мы постараемся сделать кнопку для Вас.</li>
                      <li>Если Вы блогер и <strong>хотите рассказать о нашей кнопке</strong> и получить доход - мы будем рады сотрудничать!</li>
                  </ul>
                </p>
                <Link className="btn btn-blue" to="/">На главную!</Link>
            </div>
        </div>
    </div>
  )
}

export default B2B